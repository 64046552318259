import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { affiliateProfile } from '../../../actions/affiliate';
import { userProfile } from '../../../actions/auth';
import { clearModal, showModal } from '../../../actions/modal';
import api from '../../../commonjs/api';
import { CLIENT_ID, CLIENT_SECRET, RECAPTCHA_SITEKEY } from '../../../constants/config';
import { MOBILE_MODAL } from '../../../constants/types';
import SetPinBanner from '../../../images/fairdee-x-plus/auth/set-pin.svg';
import BackArrow from '../../../images/fairdee-x-plus/icons/back-arrow.svg';
import { RootState } from '../../../index';
import {
  errorHandler,
  isAdmin,
  isEmailOtp,
  isProfileComplete,
  isSuperAdmin,
  setAccessToken,
  setAxiosHeaders,
  setRefreshToken,
  setUserData,
  validatePassword,
} from '../../../utils/helper';
import { Mixpanel } from '../../../utils/tracking';
import InlineError from '../../InlineError';
import InfoModal from '../common/InfoModal';
import RecaptchaButton from '../common/RecaptchaButton';
import Otp from './Otp';
import WelcomeProfileNudge from './WelcomeProfileNudge';
import './_login-register.scss';

interface Props {
  close?: () => void;
  action?: (passCode: string) => void;
  username: string;
  isDirectAgent?: boolean | undefined;
  isIg?: boolean | undefined;
  isBusinessManager?: boolean | undefined;
  emailLogin?: boolean;
}

interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

interface ErrorResponse {
  response: any;
}

interface Errors {
  passCode?: string;
}

const OTP_REQUEST_LIMIT = 10;

interface GetCaptcha {
  token?: string;
  btnName?: string;
}

const PasscodeVerification: React.FC<Props> = ({
  close,
  username,
  isDirectAgent,
  isIg,
  isBusinessManager,
  emailLogin,
}) => {
  const languageMap = useSelector((state: RootState) => state.languageMap.fairdeeXplus),
    [passCode, setPassCode] = useState(''),
    [disableBtn, setDisableBtn] = useState(true),
    [loading, setLoading] = useState(false),
    user = useSelector((state: RootState) => state.user),
    [isPasswordVisible, setIsPasswordVisible] = useState(false),
    affiliate = useSelector((state: RootState) => state.affiliate),
    systemSettings = useSelector((state: RootState) => state.systemSettings),
    dispatch = useDispatch(),
    history = useHistory(),
    [errors, setErrors] = useState<Errors>({}),
    trackEvent = (window as { [key: string]: any })['trackEvent'] as any,
    captchaSiteKey = RECAPTCHA_SITEKEY,
    [captchaProps, setCaptchaProps] = useState<GetCaptcha>({
      token: '',
      btnName: '',
    });

  let path = '/signup',
    params = window.location.search;

  if (params) {
    path = `${path}${params}`;
  }

  useEffect(() => {
    if (user.id) {
      trackEvent &&
        trackEvent.register({
          Name: user.fullname || '',
          Identity: user.id,
        });
      if (user.affiliates.length > 1) {
        redirectToWelcome();
      } else {
        setAffiliateProfile();
      }
    }
  }, [user]);

  function redirectToWelcome() {
    dispatch(clearModal());
    history.push('/welcome');
  }

  useEffect(() => {
    let errors = validate();
    if (!errors.passCode) {
      setDisableBtn(false);
    }
  }, [passCode]);

  useEffect(() => {
    if (captchaProps.token) {
      handleActionButton(captchaProps);
    }
  }, [captchaProps.token]);

  useEffect(() => {
    if (affiliate.id) {
      let userObj = {
        email: affiliate.user.email,
        id: affiliate.user.id,
        agent_code: affiliate.agent_code,
      };
      if (user.affiliates.length === 1) {
        let userProperty = {
          ...userObj,
          isAdmin: isAdmin() || isSuperAdmin() || '',
          accountType: affiliate.account_type || '',
        };
        trackEvent && trackEvent.userProperty(userProperty);
      }
      if (affiliate.is_signup_complete || (isEmailOtp() && affiliate.has_broker_license !== null)) {
        history.push('/buy-insurance');
      } else {
        history.push(path);
      }
      dispatch(clearModal());
      if (!isProfileComplete(affiliate) && affiliate.is_signup_complete) {
        openProfileNudgeModal();
      }
    }
  }, [affiliate]);

  function openProfileNudgeModal() {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <WelcomeProfileNudge />,
        uid: 'PROFILE_NUDGE',
        backgroundClick: true,
      })
    );
  }

  function openInfoModal(title: string, text: string, btnText: string, action: any) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <InfoModal title={title} text={text} btnText={btnText} action={action} />,
        uid: 'INFO_MODAL',
        className: 'full-screen',
      })
    );
  }

  function handleInputChange(value: string) {
    setPassCode(value);
    let errors = validate();
    if (!errors.passCode) {
      setDisableBtn(false);
    }
  }
  function togglePasswordVisibility() {
    setIsPasswordVisible(prevState => !prevState);
  }
  function validate() {
    let errors: Errors = {
      passCode: '',
    };

    if (!validatePassword(passCode)) {
      errors.passCode = languageMap[`Invalid Password`];
    }

    return errors;
  }

  function setAffiliateProfile() {
    dispatch(affiliateProfile());
  }

  function getUserProfile() {
    dispatch(userProfile(isIg || isDirectAgent || isBusinessManager));
  }

  function handlePasscodeLogin(headers?: any) {
    let params: any = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      grant_type: 'password',
      username, // user mobile number
      password: passCode,
      is_mlm: false,
      is_inspection_garage: false,
      is_direct_agent: false,
      is_business_manager: false,
      username_type: emailLogin ? 'email' : 'phone',
    };
    if (!(isIg || isDirectAgent || isBusinessManager)) {
      params.is_mlm = true;
    } else if (isIg) {
      params.is_inspection_garage = true;
    } else if (isBusinessManager) {
      params.is_business_manager = true;
    } else {
      params.is_direct_agent = true;
    }
    setLoading(true);
    api.user
      .login(params, headers)
      .then((response: LoginResponse) => {
        setAxiosHeaders(response.access_token);
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        setUserData({ accessToken: response.access_token });
        Mixpanel.track('Login/signup', {
          object: 'Login',
          objectContext: {
            parent: 'Otp Modal',
          },
          action: 'Successful',
        });
        getUserProfile();
      })
      .catch((error: ErrorResponse) => {
        setLoading(false);
        setDisableBtn(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error === 'invalid_pin' &&
          OTP_REQUEST_LIMIT - error.response.data.remaining_attempts <= 2
        ) {
          openInfoModal(languageMap[`Incorrect Password`], languageMap[`Please check and enter correct Password.`], '', null);
          return;
        } else if (
          (error.response &&
            error.response.data &&
            error.response.data.remaining_attempts !== null &&
            OTP_REQUEST_LIMIT - error.response.data.remaining_attempts > 2) ||
          (error.response && error.response.data && error.response.data.detail)
        ) {
          openInfoModal(languageMap[`3 wrong Password attempts`], languageMap[``], languageMap[`Reset Password`], () =>
            resetPin()
          );
        } else {
          errorHandler(error.response, true);
        }
      });
  }

  function handleNext(params?: any) {
    let tempErrors = validate();
    setErrors(tempErrors);

    if (errors.passCode) {
      return;
    }
    handlePasscodeLogin(params);
  }

  function openOtpScreen(intent: string, emailOtp: string) {
    dispatch(
      showModal(MOBILE_MODAL, {
        component: <Otp username={username} intent={intent} emailOtp={emailOtp} emailLogin={emailLogin} />,
        uid: 'OTP_MODAL',
      })
    );
  }

  function requestOtp(intent: string = '', headers?: any) {
    let params = {
      username,
      intent,
      username_type: emailLogin ? 'email' : 'phone',
      is_email_otp: emailLogin ? 'true' : isEmailOtp(),
    };
    setDisableBtn(true);
    setLoading(true);
    api.user
      .requestOtp(params, headers)
      .then((response) => {
        openOtpScreen(intent, response.username);
        setDisableBtn(false);
        setLoading(true);
      })
      .catch((error: ErrorResponse) => {
        setLoading(true);

        let errors: any = errorHandler(error.response);
        if (errors && errors.message === 'Too many OTP requests! Account blocked for 24 hours.') {
          openInfoModal(
            '',
            languageMap[
              `Your account has been blocked for 24 hours after multiple failed attempts. Please try again tomorrow`
            ],
            '',
            null
          );
        }
      });
  }

  function resetPin(params?: any) {
    requestOtp('reset_pin', params);
  }

  function getCaptchaToken(name: string, token: string) {
    if (token) {
      setCaptchaProps({
        token,
        btnName: name,
      });
    }
  }

  function handleActionButton(captchaProps: any) {
    let params = {
      headers: {
        'G-Recaptcha-Response': captchaProps.token,
      },
    };
    if (captchaProps.btnName === 'input_pin') {
      handleNext(params);
    } else if (captchaProps.btnName === 'reset_pin') {
      resetPin(params);
    }
  }

  function generateCaptchaButton() {
    return (
      <>
        <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <RecaptchaButton
            action={getCaptchaToken}
            btnType="button"
            name="input_pin"
            label={languageMap['Next']}
            loading={loading}
            disableBtn={disableBtn}
          />
          <RecaptchaButton
            action={getCaptchaToken}
            btnType="text"
            name="reset_pin"
            preTxt={languageMap[`Forgot your Password?`]}
            label={languageMap[`Reset here`]}
          />
        </GoogleReCaptchaProvider>
      </>
    );
  }
  function generateNormalButton() {
    return (
      <>
        <div className="buttons-wrapper">
          <Button className={'orange-button ' + (loading || disableBtn ? 'disabled' : '')} onClick={handleNext}>
            {languageMap[`Next`]}
          </Button>
        </div>
        <div className="link-wrap">
          <span className={'link ' + (loading ? 'disabled' : '')}>
            {languageMap[`Forgot your Password?`]}
            <span onClick={resetPin}> {languageMap[`Reset here`]}</span>
          </span>{' '}
        </div>
      </>
    );
  }
  function generateButton(): any {
    if (!systemSettings.should_validate_recaptcha) {
      return generateNormalButton();
    } else {
      return generateCaptchaButton();
    }
  }

  return (
    <div className="mobile-layout login-register otp">
      <div className="header">
        <div className="back" onClick={close}>
          <img src={BackArrow} alt="back_arrow" />
        </div>
        <p className="text">{languageMap[`Verification`]} </p>
      </div>
      <div className="content-wrapper">
        <div className="otp-img-wrap">
          <img src={SetPinBanner} alt="set-pin-screen" />
        </div>
        <div className="text-wrapper">
          <h2 className="title-text">{languageMap[`Enter Password`]}</h2>
          <p className="sub-text">{languageMap[`Enter your Password to log into your account.`]} </p>
        </div>
        <div className={'input-wrapper ' + (errors.passCode ? 'error' : '')}>
          <div className="input-row">
            <input className = "password" type={isPasswordVisible ? 'text' : 'password'} onChange={(e: any) => handleInputChange(e.target.value)} />
            <button className = "toggleEyeButton" onClick={togglePasswordVisibility}>
              {isPasswordVisible ? (
                <i className="fa fa-eye-slash"></i>
              ) : (
                <i className="fa fa-eye"></i>
              )}
            </button>
          </div>
        </div>
        {generateButton()}
      </div>
    </div>
  );
};

export default PasscodeVerification;
