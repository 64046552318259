import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import validator from 'validator';
import { hideModal } from '../../actions/modal';
import api from '../../commonjs/api';
import { insurerInfoMap } from '../../commonjs/commonData';
import InlineError from '../../components/InlineError';
import { CLAIM_OPTIONS, THEFT_SUPPORTED_INSURANCE_CLASSES } from '../../constants';
import cross from '../../images/close.svg';
import {
  errorHandler,
  getPreviousInsurer,
  getUrlparamsAsObject,
  setLanguageToElement,
  toFixedDecimal,
} from '../../utils/helper';

class ManualQuoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.urlParams = getUrlparamsAsObject();
    this.state = {
      data: {
        insurer: '',
        plan_name: '',
        insurance_class: '',
        gross_premium: '',
        tax: '',
        duty: '',
        premium_after_tax: '',
        sum_insured: props.filter && !this.urlParams.renewals ? props.filter.sum_insured || '' : '',
        fire_theft: props.filter && !this.urlParams.renewals ? props.filter.sum_insured || '' : '',
        flood_protection: props.filter && !this.urlParams.renewals ? props.filter.sum_insured || '' : '',
        theft: '0',
        terrorism: '0',
        deductible: '0',
        garage_type: '',
        bt: '',
        third_party: '',
        bi: '',
        bail_bond: '',
        hospitalization: '',
        pa: '',
        number_of_garages: '',
        garage_list: '',
        hospitals_list: '',
        number_of_hospitals: '',
        number_of_branches: '',
        branches_list: '',
        number_of_dealers: '',
        dealers_list: '',
        is_genx: '',
        is_package_pricing: '',
        is_cashback_supported: '',
        auto_calculate: true,
        apply_management_fee: null,
        apply_level_commission: null,
        apply_referral: null,
        vmi_car_code: '',
        insurer_commission_rate: '',
      },
      has_claims: 'unknown',
      errors: {},
      loading: false,
      affiliate: props.quotationAffiliate || {},
      vehicleCategory: [],
      insuranceClasses: [],
    };
    this.previousInsurer = false;
  }

  componentDidMount() {
    setLanguageToElement();
    let vehicleCatParams = {};
    if (this.props.vehicleCategoryParams) {
      vehicleCatParams = this.props.vehicleCategoryParams;
    }
    this.getVehicleCategories(vehicleCatParams);
    this.getInsuranceClasses();
    if (this.props.data) {
      if (this.props.isRenewalFlow || this.props.isReviseFlow || this.props.premiumUpdate || this.props.isCoaFlow) {
        let data = { ...this.state.data, ...this.props.data };
        let prevIns = [];
        if (this.props.data.insurer) {
          prevIns = Object.keys(this.props.insurers).filter((id) => +id === this.props.data.insurer.id);
        }
        if (prevIns.length) {
          this.previousInsurer = prevIns[0];
          if (this.previousInsurer) {
            data.insurer = parseInt(this.previousInsurer);
          }
        }
        if (this.props.quotationAffiliate && this.props.quotationAffiliate.account_type !== 'mlm_agent') {
          data = {
            ...data,
            apply_level_commission: true,
            apply_management_fee: true,
            apply_referral: true,
          };
        }
        if (this.props.isRenewalFlow) {
          this.setState({
            data,
            has_claims: this.props.data.has_claims !== null ? this.props.data.has_claims : 'unknown',
          });
        } else {
          this.setState({
            data,
          });
        }
        return;
      }

      if (this.props.data.sum_insured && !this.urlParams.renewals) {
        let data = this.state.data;
        data.sum_insured = this.props.data.sum_insured || '';
        data.fire_theft = this.props.data.sum_insured || '';
        data.flood_protection = this.props.data.sum_insured || '';
        this.setState({
          data,
        });
      }

      if (this.props.data?.is_manual_quote === true) {
        let data = { ...this.state.data };
        data.insurer = this.props.data.current_insurer;
        data.garage_type = this.props.data.garage_type;
        data.insurance_class = this.props.data.insurance_class;
        data.affiliate = this.props.data.affiliate.toString();
        if (this.props.data.vmi_car_code) {
          let vmi_car_code = this.state.vehicleCategory.filter((vehicleCategory) =>
            vehicleCategory.text.includes(this.props.data.vmi_car_code)
          );
          data.vmi_car_code = vmi_car_code.value;
          data.fairdee_quotation_query_id = this.props.data.id;
        }
        this.setState({
          data,
        });
      }
    }

    if ((!this.props.quotationAffiliate && this.props.affiliate) || (this.props.data && this.props.data.affiliate)) {
      this.getAffiliate(this.props.affiliate || this.props.data.affiliate);
    } else if (this.props.quotationAffiliate && this.props.quotationAffiliate.account_type !== 'mlm_agent') {
      this.setState({
        data: {
          ...this.state.data,
          apply_level_commission: true,
          apply_management_fee: true,
          apply_referral: true,
        },
      });
    }

    if (this.props.insurers) {
      this.insurersMap = {};
      if (this.props.insurers.constructor === Object) {
        Object.keys(this.props.insurers).forEach((key) => {
          this.insurersMap[this.props.insurers[key].name] = this.props.insurers[key].id;
        });
      }
      let prevIns = undefined;
      if (this.props.data && this.props.data.current_insurer) {
        prevIns = this.props.data.current_insurer;
      }
      this.previousInsurer = getPreviousInsurer(prevIns) || null;
      if (this.previousInsurer) {
        this.setState({
          data: { ...this.state.data, insurer: parseInt(this.previousInsurer, 10) },
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.filter && this.props.filter.sum_insured !== nextProps.filter.sum_insured) {
      this.setState({
        data: {
          ...this.state.data,
          sum_insured: nextProps.filter.sum_insured,
          fire_theft: nextProps.filter.sum_insured,
          flood_protection: nextProps.filter.sum_insured,
        },
      });
    }
    if (this.props.data && this.props.data.sum_insured && !this.urlParams.renewals) {
      let data = this.state.data;
      data.sum_insured = this.props.data.sum_insured || '';
      data.fire_theft = this.props.data.sum_insured || '';
      data.flood_protection = this.props.data.sum_insured || '';

      this.setState({
        data,
      });
    }
  }

  close = () => {
    this.props.hideModal(null, {
      ...this.props,
    });
  };

  getInsuranceClasses = () => {
    if (this.state.insuranceClasses.length) {
      return;
    }
    api.masterData.getInsuranceClasses().then((resp) => {
      let INSURANCE_CLASSES = [];
      resp = resp.results;
      resp = resp.map((data) => {
        INSURANCE_CLASSES.push(data.name);
      });
      this.setState({
        insuranceClasses: INSURANCE_CLASSES,
      });
    });
  };

  getVehicleCategories = () => {
    let params = {
      product: 'voluntary_insurance',
    };
    api.masterData.getVehiclecategories(params).then((resp) => {
      resp = resp.map((category) => {
        category.text = `${category.name} - ${category.value.toLowerCase()}`;
        category.value = category.id;
        let data = { ...this.state.data };
        if (this.props.data?.is_manual_quote) {
          if (category.identifier === this.props.data.vmi_car_code) {
            data.vmi_car_code = category.id;
          }
          this.setState({
            data,
          });
        }
        return category;
      });
      let vmi_car_code = null;
      if (this.state.data.vmi_car_code && this.props.data?.is_sale_transfer) {
        vmi_car_code = resp.filter((vc) => vc.identifier === this.state.data.vmi_car_code)[0].value;
        this.setState({
          data: { ...this.state.data, vmi_car_code: vmi_car_code },
        });
      }
      this.setState({
        vehicleCategory: resp,
      });
    });
  };

  getAffiliate = (affiliateId) => {
    if (affiliateId && !isNaN(affiliateId)) {
      api.crm
        .affiliate(affiliateId, {
          with_commission_rates: false,
          with_affiliate_discounts: false,
        })
        .then((res) => {
          this.setState({
            affiliate: res.message,
            data: {
              ...this.state.data,
              apply_level_commission: res.message.account_type !== 'mlm_agent',
              apply_management_fee: res.message.account_type !== 'mlm_agent',
              apply_referral: res.message.account_type !== 'mlm_agent' || res.message.account_type !== null,
            },
          });
        });
    }
  };

  handleNumberFormat = (name) => (data) => {
    let obj = {},
      value = parseFloat(data.value || 0);
    if (name === 'premium_after_tax' && this.state.data.auto_calculate) {
      let premium_after_tax = value;
      let netPremium = premium_after_tax;
      let tax = (netPremium * 0.07) / 1.07;
      let duty = ((netPremium - tax) * 0.004) / 1.004;
      let gross_premium = netPremium - tax - duty;

      obj.premium_after_tax = premium_after_tax || '';
      obj.tax = tax ? tax.toFixed(2) : '';
      obj.duty = duty ? duty.toFixed(2) : '';
      obj.gross_premium = gross_premium ? gross_premium.toFixed(2) : '';
    }

    this.setState({
      data: { ...this.state.data, [name]: data.value, ...obj },
    });
  };

  handleChange = (name) => (event) => {
    let obj = {};
    if (name === 'vmi_car_code') {
    }
    if (name === 'has_claims') {
      this.setState({
        has_claims: event.target.value,
      });
      return;
    }
    if (name === 'insurer') {
      let insurer = this.props.insurers[event.target.value];
      let extraInfo = insurerInfoMap[insurer.name] || {};

      if (extraInfo.branches_list) {
        obj.branches_list = extraInfo.branches_list;
      } else {
        obj.branches_list = null;
      }

      if (extraInfo.number_of_branches) {
        obj.number_of_branches = extraInfo.number_of_branches;
      } else {
        obj.number_of_branches = 0;
      }

      if (extraInfo.hospitals_list) {
        obj.hospitals_list = extraInfo.hospitals_list;
      } else {
        obj.hospitals_list = null;
      }

      if (extraInfo.number_of_hospitals) {
        obj.number_of_hospitals = extraInfo.number_of_hospitals;
      } else {
        obj.number_of_hospitals = 0;
      }

      if (this.state.data.garage_type === '0') {
        obj.dealers_list = null;
        obj.number_of_dealers = 0;

        if (extraInfo.authorizedgarage) {
          obj.garage_list = extraInfo.authorizedgarage;
        } else {
          obj.garage_list = null;
        }

        if (extraInfo.authorizedgarageNumber) {
          obj.number_of_garages = extraInfo.authorizedgarageNumber;
        } else {
          this.state.data.number_of_garages = 0;
        }
      }
      if (this.state.data.garage_type === '1') {
        obj.garage_list = null;
        obj.number_of_garages = 0;

        if (extraInfo.dealershipGarage) {
          obj.dealers_list = extraInfo.dealershipGarage;
        } else {
          obj.dealers_list = null;
        }

        if (extraInfo.dealershipGarageNumber) {
          obj.number_of_dealers = extraInfo.dealershipGarageNumber;
        } else {
          this.state.data.number_of_dealers = 0;
        }
      }
    }
    if (name === 'garage_type' && this.state.data.insurer) {
      let insurer = this.props.insurers[this.state.data.insurer];
      let extraInfo = insurerInfoMap[insurer.name] || {};

      if (event.target.value === '0') {
        obj.dealers_list = null;
        obj.number_of_dealers = 0;

        if (extraInfo.authorizedgarage) {
          obj.garage_list = extraInfo.authorizedgarage;
        } else {
          obj.garage_list = null;
        }

        if (extraInfo.authorizedgarageNumber) {
          obj.number_of_garages = extraInfo.authorizedgarageNumber;
        } else {
          obj.number_of_garages = 0;
        }
      }

      if (event.target.value === '1') {
        obj.garage_list = null;
        obj.number_of_garages = 0;

        if (extraInfo.dealershipGarage) {
          obj.dealers_list = extraInfo.dealershipGarage;
        } else {
          obj.dealers_list = null;
        }

        if (extraInfo.dealershipGarageNumber) {
          obj.number_of_dealers = extraInfo.dealershipGarageNumber;
        } else {
          obj.number_of_dealers = 0;
        }
      }
    }
    console.log(name, event.target.value);
    if (name === 'auto_calculate') {
      obj.auto_calculate = event.target.value === 'true';
    }
    if (name === 'apply_management_fee') {
      obj.apply_management_fee = event.target.value === 'true';
    }
    if (name === 'apply_level_commission') {
      obj.apply_level_commission = event.target.value === 'true';
      obj.apply_management_fee = obj.apply_level_commission;
    }
    if (name === 'apply_referral') {
      obj.apply_referral = event.target.value === 'true';
    }

    this.setState({
      data: { ...this.state.data, [name]: event.target.value, ...obj },
    });
  };

  handleCheckboxChange = (name) => (event) => {
    let obj = {};
    if (name === 'garage_type') {
      let insurer = this.props.insurers[event.target.value];
      let extraInfo = insurerInfoMap[insurer.name] || {};

      if (extraInfo.branches_list) {
        obj.branches_list = extraInfo.branches_list;
      } else {
        obj.branches_list = null;
      }

      if (extraInfo.number_of_branches) {
        obj.number_of_branches = extraInfo.number_of_branches;
      } else {
        obj.number_of_branches = 0;
      }

      if (extraInfo.hospitals_list) {
        obj.hospitals_list = extraInfo.hospitals_list;
      } else {
        obj.hospitals_list = null;
      }

      if (extraInfo.number_of_hospitals) {
        obj.number_of_hospitals = extraInfo.number_of_hospitals;
      } else {
        obj.number_of_hospitals = 0;
      }

      if (this.state.data.garage_type === '0') {
        obj.dealers_list = null;
        obj.number_of_dealers = 0;

        if (extraInfo.authorizedgarage) {
          obj.garage_list = extraInfo.authorizedgarage;
        } else {
          obj.garage_list = null;
        }

        if (extraInfo.authorizedgarageNumber) {
          obj.number_of_garages = extraInfo.authorizedgarageNumber;
        } else {
          obj.number_of_garages = 0;
        }
      }

      if (this.state.data.garage_type === '1') {
        obj.garage_list = null;
        obj.number_of_garages = 0;

        if (extraInfo.dealershipGarage) {
          obj.dealers_list = extraInfo.dealershipGarage;
        } else {
          obj.dealers_list = null;
        }

        if (extraInfo.dealershipGarageNumber) {
          obj.number_of_dealers = extraInfo.dealershipGarageNumber;
        } else {
          obj.number_of_dealers = 0;
        }
      }
    }
    this.setState({ data: { ...this.state.data, [name]: event.target.checked, ...obj } });
  };

  validatePremiumCalculations = (data) => {
    const gross_premium = parseFloat(data.gross_premium);
    const tax = parseFloat(data.tax);
    const duty = parseFloat(data.duty);
    const premium_after_tax = parseFloat(data.premium_after_tax);
    const value = toFixedDecimal(gross_premium + tax + duty - premium_after_tax);
    if (Math.abs(value) > 1) {
      return false;
    }
    return true;
  };

  validate = (data) => {
    const messages = this.props.languageMap.messages;
    let errors = {};

    if (!data.insurer) {
      errors.insurer = 'Required Field';
    }

    if (!data.plan_name) {
      errors.plan_name = 'Required Field';
    }

    if (!data.garage_type) {
      errors.garage_type = 'Required Field';
    }

    if (!data.insurance_class) {
      errors.insurance_class = 'Required Field';
    }

    if (!data.gross_premium) {
      errors.gross_premium = 'Required Field';
    }

    if (data.gross_premium && !validator.isDecimal(data.gross_premium.toString())) {
      errors.gross_premium = 'Enter valid value';
    }

    if (data.commission_rate) {
      if (!validator.isDecimal(data.commission_rate.toString())) {
        errors.commission_rate = 'Enter valid value';
      }
      if (data.commission_rate < 0) {
        errors.commission_rate = `${messages.moreValue} 0`;
      }
    }

    // if ((data.commission_rate || data.mlm_commission_rate) && !data.insurer_commission_rate) {
    //   errors.insurer_commission_rate = "Required Field";
    // }

    if (data.insurer_commission_rate) {
      if (!validator.isDecimal(data.insurer_commission_rate.toString())) {
        errors.insurer_commission_rate = 'Enter valid value';
      }
      if (data.insurer_commission_rate < 0) {
        errors.insurer_commission_rate = `${messages.moreValue} 0`;
      }
    }

    if (data.apply_level_commission === false && !data.mlm_commission_rate) {
      errors.mlm_commission_rate = messages.required;
    }

    if (data.mlm_commission_rate) {
      if (!validator.isDecimal(data.mlm_commission_rate.toString())) {
        errors.mlm_commission_rate = 'Enter valid value';
      } else if (data.mlm_commission_rate < 0) {
        errors.mlm_commission_rate = `${messages.moreValue} 0`;
      }
      if (data.apply_level_commission === null) {
        errors.apply_level_commission = messages.required;
      }
      if (data.apply_referral === null) {
        errors.apply_referral = messages.required;
      }
      if (data.apply_management_fee === null) {
        errors.apply_management_fee = messages.required;
      }
    }
    if (this.state.affiliate.account_type !== null) {
      if (data.apply_referral === null) {
        errors.apply_referral = messages.required;
      }
    }
    if (!data.tax) {
      errors.tax = 'Required Field';
    }

    if (data.tax && !validator.isDecimal(data.tax.toString())) {
      errors.tax = 'Enter valid value';
    }

    if (!data.duty) {
      errors.duty = 'Required Field';
    }

    if (data.duty && !validator.isDecimal(data.duty.toString())) {
      errors.duty = 'Enter valid value';
    }

    if (!data.premium_after_tax) {
      errors.premium_after_tax = 'Required Field';
    }

    if (data.premium_after_tax && !validator.isDecimal(data.premium_after_tax.toString())) {
      errors.premium_after_tax = 'Enter valid value';
    }

    if (!data.sum_insured) {
      errors.sum_insured = 'Required Field';
    }

    if (data.sum_insured && !validator.isDecimal(data.sum_insured.toString())) {
      errors.sum_insured = 'Enter valid value';
    }

    if (data.fire_theft && !validator.isDecimal(data.fire_theft.toString())) {
      errors.fire_theft = 'Enter valid value';
    }

    if (data.flood_protection && !validator.isDecimal(data.flood_protection.toString())) {
      errors.flood_protection = 'Enter valid value';
    }

    if (data.theft && !validator.isDecimal(data.theft.toString())) {
      errors.theft = 'Enter valid value';
    }

    if (data.terrorism && !validator.isDecimal(data.terrorism.toString())) {
      errors.terrorism = 'Enter valid value';
    }

    if (data.deductible && !validator.isDecimal(data.deductible.toString())) {
      errors.deductible = 'Enter valid value';
    }

    if (data.bt && !validator.isDecimal(data.bt.toString())) {
      errors.bt = 'Enter valid value';
    }

    if (data.third_party && !validator.isDecimal(data.third_party.toString())) {
      errors.third_party = 'Enter valid value';
    }

    if (data.bi && !validator.isDecimal(data.bi.toString())) {
      errors.bi = 'Enter valid value';
    }

    if (data.bail_bond && !validator.isDecimal(data.bail_bond.toString())) {
      errors.bail_bond = 'Enter valid value';
    }

    if (data.hospitalization && !validator.isDecimal(data.hospitalization.toString())) {
      errors.hospitalization = 'Enter valid value';
    }

    if (data.pa && !validator.isDecimal(data.pa.toString())) {
      errors.pa = 'Enter valid value';
    }

    if (data.number_of_garages && !validator.isDecimal(data.number_of_garages.toString())) {
      errors.number_of_garages = 'Enter valid value';
    }

    if (data.number_of_hospitals && !validator.isDecimal(data.number_of_hospitals.toString())) {
      errors.number_of_hospitals = 'Enter valid value';
    }

    if (data.number_of_branches && !validator.isDecimal(data.number_of_branches.toString())) {
      errors.number_of_branches = 'Enter valid value';
    }

    if (data.number_of_dealers && !validator.isDecimal(data.number_of_dealers.toString())) {
      errors.number_of_dealers = 'Enter valid value';
    }

    if (
      data.gross_premium &&
      data.tax &&
      data.duty &&
      data.premium_after_tax &&
      !this.validatePremiumCalculations(data)
    ) {
      errors.premium_after_tax = messages.incorrectCalculationGrossPremium;
    }

    if (!data.vmi_car_code) {
      errors.vmi_car_code = 'Required Field';
    }

    return errors;
  };

  save = () => {
    let errors = this.validate(this.state.data);
    let vehicleData = this.props.data || this.props.filter.vehicleData || {};

    if (this.props.type === 'bike') {
      vehicleData = this.props.data || this.props.filter.bikeData || {};
    }

    this.setState({
      errors,
    });

    if (Object.keys(errors).length) {
      return;
    }

    let params = { ...this.state.data };
    // Manual quotes can't be enabled for instant purchase.
    params.can_issue_policy_online = false;
    params.model_description = vehicleData.model_description_id || vehicleData.description;
    params.model_year = vehicleData.manufacture_year;
    params.min_year = vehicleData.manufacture_year;
    params.max_year = vehicleData.manufacture_year;
    params.min_suminsured = params.sum_insured;
    params.max_suminsured = params.sum_insured;
    if (this.state.affiliate.account_type !== 'mlm_agent') {
      params.apply_level_commission = false;
      params.apply_management_fee = false;
      if (this.state.affiliate.account_type !== null) {
        params.apply_referral = false;
      }
    }
    params.is_admin = true;

    Object.keys(params).forEach((key) => params[key] === '' && delete params[key]);

    if (params.gross_premium) {
      params.gross_premium = parseFloat(params.gross_premium, 10).toFixed(2);
    }

    if (params.tax) {
      params.tax = parseFloat(params.tax, 10).toFixed(2);
    }

    if (params.duty) {
      params.duty = parseFloat(params.duty, 10).toFixed(2);
    }

    if (params.premium_after_tax) {
      params.premium_after_tax = parseFloat(params.premium_after_tax, 10).toFixed(2);
    }

    if (params.sum_insured) {
      params.sum_insured = parseFloat(params.sum_insured, 10).toFixed(2);
    }

    if (params.fire_theft) {
      params.fire_theft = parseFloat(params.fire_theft, 10).toFixed(2);
    }

    if (params.flood_protection) {
      params.flood_protection = parseFloat(params.flood_protection, 10).toFixed(2);
    }

    if (params.theft) {
      params.theft = parseFloat(params.theft, 10).toFixed(2);
    } else {
      params.theft = 0;
    }

    if (params.terrorism) {
      params.terrorism = parseFloat(params.terrorism, 10).toFixed(2);
    } else {
      params.terrorism = 0;
    }

    if (params.deductible) {
      params.deductible = parseFloat(params.deductible, 10).toFixed(2);
    } else {
      params.deductible = 0;
    }

    if (params.bt) {
      params.bt = parseFloat(params.bt, 10).toFixed(2);
    }

    if (params.third_party) {
      params.third_party = parseFloat(params.third_party, 10).toFixed(2);
    }

    if (params.bi) {
      params.bi = parseFloat(params.bi, 10).toFixed(2);
    }

    if (params.bail_bond) {
      params.bail_bond = parseFloat(params.bail_bond, 10).toFixed(2);
    }

    if (params.hospitalization) {
      params.hospitalization = parseFloat(params.hospitalization, 10).toFixed(2);
    }

    if (params.pa) {
      params.pa = parseFloat(params.pa, 10).toFixed(2);
    }

    if (params.number_of_garages) {
      params.number_of_garages = parseFloat(params.number_of_garages, 10);
    }

    if (params.number_of_hospitals) {
      params.number_of_hospitals = parseFloat(params.number_of_hospitals, 10);
    }

    if (params.number_of_branches) {
      params.number_of_branches = parseFloat(params.number_of_branches, 10);
    }

    if (params.number_of_dealers) {
      params.number_of_dealers = parseFloat(params.number_of_dealers, 10);
    }

    if (this.props.data?.is_manual) {
      params.is_manual = true;
    }

    if (!params.affiliate) {
      params.affiliate = this.props.affiliate;
    }

    if (this.props.isReviseFlow || this.props.isRenewalFlow || this.props.isCoaFlow) {
      params.for_renewal = this.props.isRenewalFlow ? true : false;
      if (this.props.data.id) {
        delete params.ic;

        this.updatePriceList(params, this.props.data.id);
        return;
      }
    }
    if (this.props.quotationQueryId) {
      params.fairdee_quotation_query_id = this.props.quotationQueryId;
    }
    if (this.props.data?.is_manual_quote === true) {
      params.fairdee_quotation_query_id = this.props.data.fairdee_quotation_query_id;
    }
    this.updatePriceList(params);
  };

  updatePriceList = (params, id = null) => {
    let apiCall = null;

    if (id) {
      apiCall = api.crm.patchPriceList(params, id);
    } else {
      apiCall = api.crm.manualPriceList(params);
    }

    apiCall
      .then((resp) => {
        if (this.props.action) {
          this.props.action(null, resp.id, resp, this.state.has_claims, this.props.isCoaEdit); //third and fourth  params are required for renewal flow, fifth for coa flow
        }
        this.close();
      })
      .catch((error) => {
        errorHandler(error.response, true);
      });
  };

  renderInsuranceClass = () => {
    const { data, errors, insuranceClasses } = this.state;
    const languageMap = this.props.languageMap.components.manualQuote;
    let vehicleData = { ...(this.props.filter.vehicleData || {}), ...(this.props.data || {}) };
    if (this.props.type === 'bike') {
      vehicleData = { ...(this.props.filter.bikeData || {}), ...(this.props.data || {}) };
      return (
        <div className="form-element-wrap">
          <InputLabel htmlFor="age-simple">{languageMap.form.textThree}</InputLabel>
          <FormGroup row>
            {insuranceClasses
              .filter((ic) => ic.name !== '2')
              .map((ic) => (
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.insurance_class === ic}
                      onChange={this.handleChange('insurance_class')}
                      value={ic}
                      name="insurance_class"
                      aria-label={ic}
                    />
                  }
                  label={ic}
                />
              ))}
          </FormGroup>
          {errors.insurance_class && <InlineError text={errors.insurance_class} />}
        </div>
      );
    }
    return (
      <div className="form-element-wrap">
        <InputLabel htmlFor="age-simple">{languageMap.form.textThree}</InputLabel>
        <FormGroup row>
          {insuranceClasses.map((ic) => (
            <FormControlLabel
              control={
                <Radio
                  checked={data.insurance_class === ic}
                  onChange={this.handleChange('insurance_class')}
                  value={ic}
                  name="insurance_class"
                  aria-label={ic}
                />
              }
              label={ic}
            />
          ))}
        </FormGroup>
        {errors.insurance_class && <InlineError text={errors.insurance_class} />}
      </div>
    );
  };

  render() {
    const { data, errors, loading, vehicleCategory } = this.state;
    let { insurers } = this.props;
    const languageMap = this.props.languageMap.components.manualQuote;
    let vehicleData = { ...(this.props.filter.vehicleData || {}), ...(this.props.data || {}) };

    return (
      <div className="fairdee-modal-wrapper manual-quote">
        <div className="modal-content">
          <img src={cross} className="close" alt="" onClick={this.close} />
          <div className="manual-quote-wrap">
            {Object.keys(vehicleData).length ? (
              <div className="filled-data">
                <InputLabel>Car: {vehicleData.make}</InputLabel>
                {vehicleData.make_model && <InputLabel> {vehicleData.make_model}</InputLabel>}
                {vehicleData.cc && <InputLabel> {vehicleData.cc}</InputLabel>}
                <div className="mt-16">
                  {vehicleData.manufacture_year && <InputLabel>Year: {vehicleData.manufacture_year}</InputLabel>}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="form-element-wrap">
              <TextField
                select
                id="insurer"
                label={languageMap.form.textOne}
                value={data.insurer || ''}
                margin="normal"
                error={errors.insurer ? true : false}
                helperText={errors.insurer}
                autoComplete="off"
                name="insurer"
                onChange={this.handleChange('insurer')}
                className="full-width"
                disabled={Boolean(this.previousInsurer)}
              >
                {Object.keys(insurers).map((key) => (
                  <MenuItem key={insurers[key].id} value={insurers[key].id}>
                    {insurers[key].name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="form-element-wrap">
              <TextField
                id="plan_name"
                label={languageMap.form.textTwo}
                value={data.plan_name || ''}
                margin="normal"
                error={errors.plan_name ? true : false}
                helperText={errors.plan_name}
                autoComplete="off"
                name="plan_name"
                onChange={this.handleChange('plan_name')}
                className="full-width"
              />
            </div>

            <div className="form-element-wrap">
              <label htmlFor="" className="form-label">
                {languageMap.form.textTwelve}
              </label>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.garage_type === '0'}
                      onChange={this.handleChange('garage_type')}
                      value="0"
                      name="garage_type"
                    />
                  }
                  label={languageMap.form.textThirty}
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={data.garage_type === '1'}
                      onChange={this.handleChange('garage_type')}
                      value="1"
                      name="garage_type"
                    />
                  }
                  label={languageMap.form.textThirtyOne}
                />
              </FormGroup>
              {errors.garage_type && <InlineError text={errors.garage_type} />}
            </div>

            {this.renderInsuranceClass()}

            <div className="form-element-wrap">
              <TextField
                select
                id="vehicle_category"
                label={'Vehicle Category'}
                value={data.vmi_car_code || ''}
                margin="normal"
                error={errors.vmi_car_code ? true : false}
                helperText={errors.vmi_car_code}
                autoComplete="off"
                name="vmi_car_code"
                onChange={this.handleChange('vmi_car_code')}
                className="full-width"
              >
                {vehicleCategory.map((key) => (
                  <MenuItem key={key.value} value={key.value}>
                    {key.text}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="form-element-wrap">
              <label htmlFor="" className="form-label">
                {languageMap.form.autoCalculate}
              </label>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.data.auto_calculate === true}
                      onChange={this.handleChange('auto_calculate')}
                      value={true}
                      name="auto_calculate"
                    />
                  }
                  label={languageMap.form.yes}
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.data.auto_calculate === false}
                      onChange={this.handleChange('auto_calculate')}
                      value={false}
                      name="auto_calculate"
                    />
                  }
                  label={languageMap.form.no}
                />
              </FormGroup>
              {errors.garage_type && <InlineError text={errors.garage_type} />}
            </div>

            {this.props.isRenewalFlow && (
              <>
                <div className="form-element-wrap">
                  <NumberFormat
                    id="tax"
                    customInput={TextField}
                    label="Previous Premium"
                    value={data.original_premium || ''}
                    margin="normal"
                    autoComplete="off"
                    name="original_premium"
                    className="full-width"
                    thousandSeparator={true}
                    disabled={true}
                  />
                </div>
                <div className="form-element-wrap">
                  <NumberFormat
                    id="tax"
                    customInput={TextField}
                    label="Previous Sum Insured"
                    value={data.original_sum_insured || ''}
                    margin="normal"
                    autoComplete="off"
                    name="original_sum_insured"
                    className="full-width"
                    thousandSeparator={true}
                    disabled={true}
                  />
                </div>
              </>
            )}
            <div className="form-element-wrap">
              <NumberFormat
                id="premium_after_tax"
                customInput={TextField}
                label={languageMap.form.textSeven}
                value={data.premium_after_tax || ''}
                margin="normal"
                error={errors.premium_after_tax ? true : false}
                helperText={errors.premium_after_tax}
                autoComplete="off"
                name="premium_after_tax"
                onValueChange={this.handleNumberFormat('premium_after_tax')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>
            {this.state.affiliate.account_type === 'mlm_agent' ? (
              <div className="form-element-wrap">
                <NumberFormat
                  id="mlm_commission_rate"
                  value={data.mlm_commission_rate || ''}
                  name={languageMap.form.mlmCommissionRate}
                  customInput={TextField}
                  label={'MLM Commission Rate'}
                  thousandSeparator={true}
                  onValueChange={this.handleNumberFormat('mlm_commission_rate')}
                  error={errors.mlm_commission_rate ? true : false}
                  helperText={errors.mlm_commission_rate}
                  autoComplete="off"
                  className="full-width"
                />
              </div>
            ) : (
              <div className="form-element-wrap">
                <NumberFormat
                  id="commission_rate"
                  value={data.commission_rate || ''}
                  name={languageMap.form.commissionRate}
                  customInput={TextField}
                  label={'Commission Rate'}
                  thousandSeparator={true}
                  onValueChange={this.handleNumberFormat('commission_rate')}
                  error={errors.commission_rate ? true : false}
                  helperText={errors.commission_rate}
                  autoComplete="off"
                  className="full-width"
                />
              </div>
            )}

            <div className="form-element-wrap">
              <NumberFormat
                id="insurer_commission_rate"
                value={data.insurer_commission_rate || ''}
                name={languageMap.form.commissionRate}
                customInput={TextField}
                label={'Insurer Commission Rate'}
                thousandSeparator={true}
                onValueChange={this.handleNumberFormat('insurer_commission_rate')}
                error={errors.insurer_commission_rate ? true : false}
                helperText={errors.insurer_commission_rate}
                autoComplete="off"
                className="full-width"
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="tax"
                customInput={TextField}
                label={languageMap.form.textFive}
                value={data.tax || ''}
                margin="normal"
                error={errors.tax ? true : false}
                helperText={errors.tax}
                autoComplete="off"
                name="tax"
                onValueChange={this.handleNumberFormat('tax')}
                className="full-width"
                thousandSeparator={true}
                disabled={this.state.data.auto_calculate}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="duty"
                customInput={TextField}
                label={languageMap.form.textSix}
                value={data.duty || ''}
                margin="normal"
                error={errors.duty ? true : false}
                helperText={errors.duty}
                autoComplete="off"
                name="duty"
                onValueChange={this.handleNumberFormat('duty')}
                className="full-width"
                thousandSeparator={true}
                disabled={this.state.data.auto_calculate}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="gross_premium"
                value={data.gross_premium || ''}
                name="gross_premium"
                customInput={TextField}
                label={languageMap.form.textFour}
                thousandSeparator={true}
                onValueChange={this.handleNumberFormat('gross_premium')}
                error={errors.gross_premium ? true : false}
                helperText={errors.gross_premium}
                autoComplete="off"
                className="full-width"
                disabled={this.state.data.auto_calculate}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="sum_insured"
                customInput={TextField}
                label={languageMap.form.textEight}
                value={data.sum_insured || ''}
                margin="normal"
                error={errors.sum_insured ? true : false}
                helperText={errors.sum_insured}
                autoComplete="off"
                name="sum_insured"
                onValueChange={this.handleNumberFormat('sum_insured')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="fire_theft"
                customInput={TextField}
                label={languageMap.form.textNine}
                value={data.fire_theft || ''}
                margin="normal"
                error={errors.fire_theft ? true : false}
                helperText={errors.fire_theft}
                autoComplete="off"
                name="fire_theft"
                onValueChange={this.handleNumberFormat('fire_theft')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="flood_protection"
                customInput={TextField}
                label={languageMap.form.textTen}
                value={data.flood_protection || ''}
                margin="normal"
                error={errors.flood_protection ? true : false}
                helperText={errors.flood_protection}
                autoComplete="off"
                name="flood_protection"
                onValueChange={this.handleNumberFormat('flood_protection')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>
            {THEFT_SUPPORTED_INSURANCE_CLASSES.includes(data.insurance_class) && (
              <>
                <div className="form-element-wrap">
                  <NumberFormat
                    id="theft"
                    customInput={TextField}
                    label={languageMap.form.theft}
                    value={data.theft || ''}
                    margin="normal"
                    error={errors.theft ? true : false}
                    helperText={errors.theft}
                    autoComplete="off"
                    name="theft"
                    onValueChange={this.handleNumberFormat('theft')}
                    className="full-width"
                    thousandSeparator={true}
                  />
                </div>

                <div className="form-element-wrap">
                  <NumberFormat
                    id="terrorism"
                    customInput={TextField}
                    label={languageMap.form.terrorism}
                    value={data.terrorism || ''}
                    margin="normal"
                    error={errors.terrorism ? true : false}
                    helperText={errors.terrorism}
                    autoComplete="off"
                    name="terrorism"
                    onValueChange={this.handleNumberFormat('terrorism')}
                    className="full-width"
                    thousandSeparator={true}
                  />
                </div>
              </>
            )}
            <div className="form-element-wrap">
              <NumberFormat
                id="deductible"
                customInput={TextField}
                label={languageMap.form.textEleven}
                value={data.deductible || ''}
                margin="normal"
                error={errors.deductible ? true : false}
                helperText={errors.deductible}
                autoComplete="off"
                name="deductible"
                onValueChange={this.handleNumberFormat('deductible')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="bt"
                customInput={TextField}
                label={languageMap.form.textThirteen}
                value={data.bt || ''}
                margin="normal"
                error={errors.bt ? true : false}
                helperText={errors.bt}
                autoComplete="off"
                name="bt"
                onValueChange={this.handleNumberFormat('bt')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="third_party"
                customInput={TextField}
                label={languageMap.form.textFourteen}
                value={data.third_party || ''}
                margin="normal"
                error={errors.third_party ? true : false}
                helperText={errors.third_party}
                autoComplete="off"
                name="third_party"
                onValueChange={this.handleNumberFormat('third_party')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="bi"
                customInput={TextField}
                label={languageMap.form.textFifteen}
                value={data.bi || ''}
                margin="normal"
                error={errors.bi ? true : false}
                helperText={errors.bi}
                autoComplete="off"
                name="bi"
                onValueChange={this.handleNumberFormat('bi')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="bail_bond"
                customInput={TextField}
                label={languageMap.form.textSixteen}
                value={data.bail_bond || ''}
                margin="normal"
                error={errors.bail_bond ? true : false}
                helperText={errors.bail_bond}
                autoComplete="off"
                name="bail_bond"
                onValueChange={this.handleNumberFormat('bail_bond')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="hospitalization"
                customInput={TextField}
                label={languageMap.form.textSeventeen}
                value={data.hospitalization || ''}
                margin="normal"
                error={errors.hospitalization ? true : false}
                helperText={errors.hospitalization}
                autoComplete="off"
                name="hospitalization"
                onValueChange={this.handleNumberFormat('hospitalization')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="pa"
                customInput={TextField}
                label={languageMap.form.textEighteen}
                value={data.pa || ''}
                margin="normal"
                error={errors.pa ? true : false}
                helperText={errors.pa}
                autoComplete="off"
                name="pa"
                onValueChange={this.handleNumberFormat('pa')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="number_of_garages"
                customInput={TextField}
                label={languageMap.form.textNineTeen}
                value={data.number_of_garages || ''}
                margin="normal"
                error={errors.number_of_garages ? true : false}
                helperText={errors.number_of_garages}
                autoComplete="off"
                name="number_of_garages"
                onValueChange={this.handleNumberFormat('number_of_garages')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <TextField
                id="garage_list"
                label={languageMap.form.textTwenty}
                value={data.garage_list || ''}
                margin="normal"
                error={errors.garage_list ? true : false}
                helperText={errors.garage_list}
                autoComplete="off"
                name="garage_list"
                onChange={this.handleChange('garage_list')}
                className="full-width"
              />
            </div>

            <div className="form-element-wrap">
              <TextField
                id="hospitals_list"
                label={languageMap.form.textTwentyOne}
                value={data.hospitals_list || ''}
                margin="normal"
                error={errors.hospitals_list ? true : false}
                helperText={errors.hospitals_list}
                autoComplete="off"
                name="hospitals_list"
                onChange={this.handleChange('hospitals_list')}
                className="full-width"
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="number_of_hospitals"
                customInput={TextField}
                label={languageMap.form.textTwentyTwo}
                value={data.number_of_hospitals || ''}
                margin="normal"
                error={errors.number_of_hospitals ? true : false}
                helperText={errors.number_of_hospitals}
                autoComplete="off"
                name="number_of_hospitals"
                onValueChange={this.handleNumberFormat('number_of_hospitals')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="number_of_branches"
                customInput={TextField}
                label={languageMap.form.textTwentyThree}
                value={data.number_of_branches || ''}
                margin="normal"
                error={errors.number_of_branches ? true : false}
                helperText={errors.number_of_branches}
                autoComplete="off"
                name="number_of_branches"
                onValueChange={this.handleNumberFormat('number_of_branches')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <TextField
                id="branches_list"
                label={languageMap.form.textTwentyFour}
                value={data.branches_list || ''}
                margin="normal"
                error={errors.branches_list ? true : false}
                helperText={errors.branches_list}
                autoComplete="off"
                name="branches_list"
                onChange={this.handleChange('branches_list')}
                className="full-width"
              />
            </div>

            <div className="form-element-wrap">
              <NumberFormat
                id="number_of_dealers"
                customInput={TextField}
                label={languageMap.form.textTwentyFive}
                value={data.number_of_dealers || ''}
                margin="normal"
                error={errors.number_of_dealers ? true : false}
                helperText={errors.number_of_dealers}
                autoComplete="off"
                name="number_of_dealers"
                onValueChange={this.handleNumberFormat('number_of_dealers')}
                className="full-width"
                thousandSeparator={true}
              />
            </div>

            <div className="form-element-wrap">
              <TextField
                id="dealers_list"
                label={languageMap.form.textTwentySix}
                value={data.dealers_list || ''}
                margin="normal"
                error={errors.dealers_list ? true : false}
                helperText={errors.dealers_list}
                autoComplete="off"
                name="dealers_list"
                onChange={this.handleChange('dealers_list')}
                className="full-width"
              />
            </div>

            <div className="form-element-wrap">
              <TextField
                select
                id="has_claims"
                label={languageMap.form.hasClaims}
                value={this.state.has_claims}
                margin="normal"
                autoComplete="off"
                name="has_claims"
                onChange={this.handleChange('has_claims')}
                className="full-width"
              >
                {Object.keys(CLAIM_OPTIONS).map((name) => (
                  <MenuItem key={CLAIM_OPTIONS[name]} value={CLAIM_OPTIONS[name]}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {this.state.affiliate.account_type === 'mlm_agent' && (
              <>
                <div className="form-element-wrap">
                  <label htmlFor="" className="form-label">
                    {languageMap.form.applyLevelCommission}
                  </label>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={data.apply_level_commission === true}
                          onChange={this.handleChange('apply_level_commission')}
                          value={true}
                          name="apply_level_commission"
                        />
                      }
                      label={languageMap.form.yes}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={data.apply_level_commission === false}
                          onChange={this.handleChange('apply_level_commission')}
                          value={false}
                          name="apply_level_commission"
                        />
                      }
                      label={languageMap.form.no}
                    />
                  </FormGroup>
                  {errors.apply_level_commission && <InlineError text={errors.apply_level_commission} />}
                </div>

                <div className="form-element-wrap">
                  <label htmlFor="" className="form-label">
                    {languageMap.form.applyManagementFee}
                  </label>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={data.apply_management_fee === true}
                          onChange={this.handleChange('apply_management_fee')}
                          value={true}
                          disabled={true}
                          name="apply_management_fee"
                        />
                      }
                      label={languageMap.form.yes}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={data.apply_management_fee === false}
                          onChange={this.handleChange('apply_management_fee')}
                          value={false}
                          disabled={true}
                          name="apply_management_fee"
                        />
                      }
                      label={languageMap.form.no}
                    />
                  </FormGroup>
                  {errors.apply_management_fee && <InlineError text={errors.apply_management_fee} />}
                </div>
              </>
            )}
            {(this.state.affiliate.account_type === 'mlm_agent' || this.state.affiliate.account_type === null) && (
              <div className="form-element-wrap">
                <label htmlFor="" className="form-label">
                  {languageMap.form.applyReferralFee}
                </label>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.apply_referral === true}
                        onChange={this.handleChange('apply_referral')}
                        value={true}
                        name="apply_referral"
                      />
                    }
                    label={languageMap.form.yes}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.apply_referral === false}
                        onChange={this.handleChange('apply_referral')}
                        value={false}
                        name="apply_referral"
                      />
                    }
                    label={languageMap.form.no}
                  />
                </FormGroup>
                {errors.apply_referral && <InlineError text={errors.apply_referral} />}
              </div>
            )}
            <div className="form-element-wrap">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.is_genx}
                      onChange={this.handleCheckboxChange('is_genx')}
                      value="is_genx"
                      name="is_genx"
                    />
                  }
                  label={languageMap.form.textTwentySeven}
                />
              </FormGroup>
            </div>

            <div className="form-element-wrap">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.is_package_pricing}
                      onChange={this.handleCheckboxChange('is_package_pricing')}
                      value="is_package_pricing"
                      name="is_package_pricing"
                    />
                  }
                  label={languageMap.form.textTwentyEight}
                />
              </FormGroup>
            </div>

            <div className="form-element-wrap">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.is_cashback_supported}
                      onChange={this.handleCheckboxChange('is_cashback_supported')}
                      value="is_cashback_supported"
                      name="is_cashback_supported"
                    />
                  }
                  label={languageMap.form.textTwentyNine}
                />
              </FormGroup>
            </div>
            <div className="button-wrap">
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" onClick={this.save}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ManualQuoteForm.propTypes = {
  languageMap: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    languageMap: state.languageMap,
    insurers: state.insurers,
    filter: state.filter,
  };
}

export default withRouter(connect(mapStateToProps, { hideModal })(ManualQuoteForm));
